import { useTranslation } from 'next-i18next';

import GooglePayButton from '@google-pay/button-react';
import tw from 'twin.macro';

import Image from '@components/elements/Image/Image';
import {
  selectBranding,
  selectModulePayU,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useLocale from '@hooks/useLocale';

import './googlePay.css';

const GooglePay = ({
  handlePay = () => {},
  handleSelect,
  isSelected,
  totalPrice = 0,
}) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  const { name } = useAppConfigSelector(selectBranding);
  const { posId, sandbox, googlePayMerchantId } =
    useAppConfigSelector(selectModulePayU);
  const { defaultRegion } = useAppConfigSelector(selectMultinational);

  const handlePayment = paymentRequest => {
    handlePay({
      authorizationCode: Buffer.from(
        paymentRequest?.paymentMethodData?.tokenizationData?.token ?? ''
      ).toString('base64'),
    });
  };

  return (
    <div
      onClick={handleSelect}
      css={[
        tw`flex items-center border border-2 rounded-md cursor-pointer my-4`,
        isSelected ? tw`border-primary text-primary` : tw`border-gray-1`,
      ]}
      id="googlePayButton"
    >
      <GooglePayButton
        buttonColor="white"
        environment={sandbox ? 'TEST' : 'PRODUCTION'}
        buttonSizeMode="fill"
        buttonType="short"
        buttonLocale={locale}
        style={{
          height: 46,
        }}
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA'],
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: 'payu',
                  gatewayMerchantId: (posId ?? '').toString(),
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: (googlePayMerchantId ?? '').toString(),
            merchantName: name,
          },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice,
            currencyCode: defaultRegion?.currencyCode,
            countryCode: locale.toUpperCase(),
          },
        }}
        onLoadPaymentData={handlePayment}
      />
      <Image
        src="/images/payment/google_pay.svg"
        width={90}
        height={90}
        alt=""
      />
      <span tw="text-base ml-0.5">
        {t('$*components.paymentTypes.payuGooglePay.label')}
      </span>
    </div>
  );
};

export default GooglePay;
