import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import type { ReactNode } from 'react';
import type { PaymentType } from '@tsTypes/PaymentType';

import CashIcon from '@assets/icons/CashIcon';
import StripeCardIcon from '@assets/icons/StripeCardIcon';
import TransferIcon from '@assets/icons/TransferIcon';
import PAYMENT_TYPES from '@constants/paymentTypes';
import usePaymentCards from '@hooks/usePaymentCards';
import checkIfPaymentTypesIncludesCard from '@utils/checkIfPaymentTypesIncludesCard';

import BlikInfo from './BlikInfo/BlikInfo';
import PaymentRightCol from './PaymentRightCol/PaymentRightCol';
import PayPoInfo from './PayPoInfo/PayPoInfo';
import PayUInfo from './PayUInfo/PayUInfo';
import Image from '@components/elements/Image/Image';

const BlikInputCode = dynamic(() => import('./BlikInputCode/BlikInputCode'));
const BlikInputCodeContent = dynamic(
  () => import('./BlikInputCode/BlikInputCodeContent')
);

const SavedPaymentCard = dynamic(() => import('./SavedPaymentCard'));

type PaymentTypeProps = {
  image: ReactNode;
  text: string;
  rightCol?: ReactNode;
  content?: ReactNode;
  component?: (isSelected: boolean, isSelectMode?: boolean) => ReactNode;
};

type PaymentTypePropsMap = Partial<{
  [key in keyof typeof PAYMENT_TYPES]?: PaymentTypeProps;
}>;

type UsePaymentType = {
  blikCode?: string;
  setBlikCode?: () => void | undefined;
  selectedPaymentType?: PaymentType | null;
  onClickPayment: (args: { paymentType: PaymentType }) => void;
  isBlikExternalMode?: boolean;
};

const usePaymentTypeProps = ({
  blikCode,
  setBlikCode,
  selectedPaymentType,
  onClickPayment,
  isBlikExternalMode = false,
}: UsePaymentType): PaymentTypePropsMap => {
  const { t } = useTranslation();
  const { data: cards = [], refetch: refetchCards } = usePaymentCards({
    enabled: checkIfPaymentTypesIncludesCard([]),
  });

  const payUCard = cards.find(
    ({ provider }) => provider === PAYMENT_TYPES.PAYU_CARD
  );
  const stripeCard = cards.find(
    ({ provider }) => provider === PAYMENT_TYPES.STRIPE_CARD
  );

  const blikProps = (paymentType: PaymentType) => ({
    image: <img src="/images/payment/blik.png" alt="" />,
    text: t('$*components.paymentTypes.blik.label'),
    ...(!isBlikExternalMode && {
      component: (isSelected: boolean) => (
        <BlikInputCode
          onClickPayment={() => {
            onClickPayment({ paymentType });
          }}
          blikCode={blikCode}
          setBlikCode={setBlikCode}
          isSelected={isSelected}
        />
      ),
    }),
    content: (
      <BlikInputCodeContent
        blikCode={blikCode}
        setBlikCode={setBlikCode}
        isSelected={paymentType === selectedPaymentType}
      />
    ),
    rightCol: (
      <PaymentRightCol>
        <BlikInfo />
      </PaymentRightCol>
    ),
  });

  const payPoProps = {
    image: (
      <img
        tw="min-w-14"
        src="/images/payment/paypo.png"
        alt=""
        loading="lazy"
      />
    ),
    text: t('$*components.paymentTypes.paypo.label'),
    rightCol: (
      <PaymentRightCol>
        <PayPoInfo />
      </PaymentRightCol>
    ),
  };

  const payUProps = {
    image: <img src="/images/payment/payu.png" alt="" loading="lazy" />,
    text: t('$*components.paymentTypes.payu.label'),
    rightCol: (
      <PaymentRightCol>
        <PayUInfo />
      </PaymentRightCol>
    ),
  };
  const payUGooglePayProps = {
    image: (
      <Image
        src="/images/payment/google_pay.svg"
        width={90}
        height={90}
        alt=""
        loading="lazy"
      />
    ),
    text: t('$*components.paymentTypes.payuGooglePay.label'),
  };

  return {
    [PAYMENT_TYPES.BLUE_MEDIA_BLIK]: blikProps(PAYMENT_TYPES.BLUE_MEDIA_BLIK),
    [PAYMENT_TYPES.PAYU_BLIK]: blikProps(PAYMENT_TYPES.PAYU_BLIK),
    [PAYMENT_TYPES.PAYU]: payUProps,
    [PAYMENT_TYPES.PAYU_CARD]: {
      image: <img src="/images/payment/visa-mc.svg" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.payuCard.label'),
      ...(payUCard && {
        component: (isSelected, isSelectMode) => {
          return (
            <SavedPaymentCard
              cardData={payUCard}
              noBorder={isSelectMode}
              isSelected={isSelected}
              allowRemove={!isSelectMode}
              refetchCards={refetchCards}
              onClickPayment={() => {
                onClickPayment({ paymentType: PAYMENT_TYPES.PAYU_CARD });
              }}
            />
          );
        },
      }),
    },
    [PAYMENT_TYPES.BLUE_MEDIA]: {
      image: <img src="/images/payment/blue-media.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.blueMedia.label'),
    },
    [PAYMENT_TYPES.TPAY]: {
      image: <img src="/images/payment/tpay.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.tpay.label'),
    },
    [PAYMENT_TYPES.STRIPE_LINK]: {
      image: <img src="/images/payment/stripe.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.stripeOnline.label'),
    },
    [PAYMENT_TYPES.PAYNOW]: {
      image: <img src="/images/payment/paynow.png" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.paynow.label'),
    },
    [PAYMENT_TYPES.PAYPO]: payPoProps,
    [PAYMENT_TYPES.PAYU_PAYPO]: payPoProps,
    [PAYMENT_TYPES.BLUE_MEDIA_CARD]: {
      image: <img src="/images/payment/visa-mc.svg" alt="" loading="lazy" />,
      text: t('$*components.paymentTypes.blueMediaCard.label'),
    },
    [PAYMENT_TYPES.STRIPE_CARD]: {
      image: <StripeCardIcon tw="w-10" />,
      text: t('$*components.paymentTypes.stripeCard.label'),
      ...(stripeCard && {
        component: (isSelected, isSelectMode) => (
          <SavedPaymentCard
            cardData={stripeCard}
            noBorder={isSelectMode}
            isSelected={isSelected}
            allowRemove={!isSelectMode}
            refetchCards={refetchCards}
            onClickPayment={() => {
              onClickPayment({ paymentType: PAYMENT_TYPES.STRIPE_CARD });
            }}
          />
        ),
      }),
    },
    [PAYMENT_TYPES.BANK_WIRE]: {
      image: <TransferIcon tw="w-10" />,
      text: t('$*components.paymentTypes.bankWire.label'),
    },
    [PAYMENT_TYPES.CASH]: {
      image: <CashIcon tw="w-10" />,
      text: t('$*components.paymentTypes.cash.label'),
    },
    [PAYMENT_TYPES.PAYU_GOOGLE_PAY]: payUGooglePayProps,
  };
};

export default usePaymentTypeProps;
