import { Trans, useTranslation } from 'next-i18next';

import { useState } from 'react';
import tw from 'twin.macro';

import InfoIcon from '@components/elements/InfoIcon/InfoIcon';
import Modal from '@components/elements/Modal/Modal';

const PayUInfo = ({ reorderPage = false }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <button
        type="button"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {reorderPage ? (
          <p tw="mt-2">
            <Trans
              i18nKey="$*components.paymentTypes.payu.paymentInformation"
              components={{
                span: <span tw="mt-2 text-primary cursor-pointer" />,
              }}
            />
          </p>
        ) : (
          <InfoIcon />
        )}
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        styles={{
          container: tw`z-60`,
        }}
      >
        <Modal.Header>{t('$*components.paymentTypes.payu.title')}</Modal.Header>
        <Modal.Content>
          {t('$*newOrderCreatePage.orderForm.summary.PayUDescription')}
          <Trans
            parent="ul"
            tw="text-justify"
            i18nKey="$*newOrderCreatePage.orderForm.summary.PayUDescription.list"
            components={{ li: <li tw="list-disc ml-5" key="0" /> }}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default PayUInfo;
