import type { ReactNode } from 'react';
import type { PaymentType } from '@tsTypes/PaymentType';

import Select from '@components/elements/Form/Select';
import PAYMENT_TYPES from '@constants/paymentTypes';

import BlikInfo from './BlikInfo/BlikInfo';
import PayPoInfo from './PayPoInfo/PayPoInfo';
import PayUInfo from './PayUInfo/PayUInfo';

type PaymentTypesReorderPageProps = {
  sortedPaymentTypes: PaymentType[];
  selectedPaymentType: PaymentType | null;
  handleClickPayment: (paymentType: PaymentType) => void;
  formatOptionLabel: (option: {
    value: PaymentType;
    isSelected: boolean;
  }) => ReactNode;
};

const PaymentTypesReorderPage = ({
  sortedPaymentTypes,
  selectedPaymentType,
  handleClickPayment,
  formatOptionLabel,
}: PaymentTypesReorderPageProps) => {
  return (
    <div tw="mb-4">
      {sortedPaymentTypes.length > 1 && (
        <Select
          name="paymentType"
          tw="w-full"
          options={sortedPaymentTypes.map(paymentType => ({
            value: paymentType,
          }))}
          value={{ value: selectedPaymentType, isSelected: true }}
          onChange={({ value }: { value: PaymentType }) => {
            handleClickPayment(value);
          }}
          formatOptionLabel={formatOptionLabel}
        />
      )}
      {selectedPaymentType === PAYMENT_TYPES.PAYU_BLIK && (
        <BlikInfo reorderPage />
      )}

      {(selectedPaymentType === PAYMENT_TYPES.PAYPO ||
        selectedPaymentType === PAYMENT_TYPES.PAYU_PAYPO) && (
        <PayPoInfo reorderPage />
      )}
      {selectedPaymentType === PAYMENT_TYPES.PAYU && <PayUInfo reorderPage />}
    </div>
  );
};

export default PaymentTypesReorderPage;
