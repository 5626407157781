import {
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import { css } from '@emotion/react';
import type { PaymentType as PaymentTypeTs } from '@tsTypes/PaymentType';
import kebabCase from 'lodash/kebabCase';
import tw, { type TwStyle } from 'twin.macro';

import SpinnerThirdIcon from '@assets/icons/SpinnerThirdIcon';
import {
  getChildByDisplayName,
  isChildTypeDisplayNameEqualTo,
} from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

type Size = 'md' | 'lg' | 'xl';

type PaymentTypeProps = {
  children: ReactNode[];
  disabled?: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  size?: Size;
  styles?: TwStyle;
  type?: PaymentTypeTs;
};

const PaymentType = ({
  children,
  disabled = false,
  isLoading = false,
  isSelected = false,
  onClick,
  size = 'lg',
  styles = {},
  type,
}: PaymentTypeProps) => {
  const childrenWithProps = Children.map(children, child =>
    isValidElement<{ size: Size }>(child)
      ? cloneElement(child, { size })
      : child
  );

  const contentChildren = getChildByDisplayName(childrenWithProps, 'Content');
  const coreChildrenWithProps = childrenWithProps?.filter(
    child => !isChildTypeDisplayNameEqualTo(child, 'Content')
  );

  const styleBySize = {
    md: tw`my-2 text-sm`,
    lg: tw`my-4`,
    xl: tw`my-4`,
  }[size];

  const twStyle = cssMerge({
    defaultCss: [
      tw`border-2 rounded-lg py-3 px-3.5 cursor-pointer`,
      isSelected ? tw`border-primary text-primary` : tw`border-gray-1`,
      isLoading ? tw`opacity-60` : tw`opacity-100`,
      styleBySize,
    ],
    ...styles,
  });

  return (
    <div tw="relative">
      {isLoading && (
        <div tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <SpinnerThirdIcon tw="w-5 text-primary animate-spin" />
        </div>
      )}
      <div
        onClick={e => {
          !disabled && onClick?.(e);
        }}
        css={twStyle}
        data-cy={`payment-type payment-type--${kebabCase(type)}`}
      >
        {coreChildrenWithProps && (
          <div tw="flex items-center w-full min-h-7">
            {coreChildrenWithProps}
          </div>
        )}
        {contentChildren}
      </div>
    </div>
  );
};

type ImageProps = {
  children: ReactElement;
  size?: Size;
};

const Image = ({ children, size }: ImageProps) => {
  return (
    <div
      css={css`
        ${tw`mr-3`};
        ${size === 'md' && tw`w-10`}
        ${size === 'lg' && tw`w-14`}
        flex: 0 0 auto;

        img,
        svg {
          ${size === 'md' && 'max-height: 24px;'}
          ${size === 'lg' && 'max-height: 30px;'}
          ${size === 'xl' && 'max-height: 45px;'}
          ${tw`table mx-auto`}
        }
      `}
    >
      {children}
    </div>
  );
};

type TextProps = { children: ReactElement | string };

const Text = ({ children }: TextProps) => (
  <div tw="text-sm sm:text-base">{children}</div>
);

type RightColProps = { children: ReactElement | null };

const RightCol = ({ children }: RightColProps) => children;

type ContentProps = { children: ReactElement };

const Content = ({ children }: ContentProps) => children;

Image.displayName = 'Image';
Text.displayName = 'Text';
RightCol.displayName = 'RightCol';
Content.displayName = 'Content';

PaymentType.Image = Image;
PaymentType.Text = Text;
PaymentType.RightCol = RightCol;
PaymentType.Content = Content;

PaymentType.displayName = 'PaymentType';

export default PaymentType;
